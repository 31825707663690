import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import swal from "sweetalert";

const Taxi = () => {
  const [formValues, setFormValues] = useState({
    tx_type: '',
    tx_trip_type: '',
    tx_return_type: '',
    tx_fname: '',
    tx_lname: '',
    tx_pick_add: '',
    tx_drop_add: '',
    tx_pick_date: '',
    tx_drop_date: '',
    tx_pick_time: '',
    tx_drop_time: '',
    tx_mob_num: '',
    tx_email: '',
    tx_comm: '',
    created_at: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (name === 'tx_mob_num' && value !== "" && !/^[0-9]+$/.test(value)) {
      swal("Error", "Enter Only Digits", "error");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let data = JSON.stringify({
      tx_type: formValues.tx_type.trim(),
      tx_trip_type: formValues.tx_trip_type.trim(),
      tx_return_type: formValues.tx_return_type.trim(),
      tx_fname: formValues.tx_fname.trim(),
      tx_lname: formValues.tx_lname.trim(),
      tx_pick_add: formValues.tx_pick_add.trim(),
      tx_drop_add: formValues.tx_drop_add.trim(),
      tx_pick_date: formValues.tx_pick_date.trim(),
      tx_drop_date: formValues.tx_drop_date.trim(),
      tx_pick_time: formValues.tx_pick_time.trim(),
      tx_drop_time: formValues.tx_drop_time.trim(),
      tx_mob_num: formValues.tx_mob_num.trim(),
      tx_email: formValues.tx_email.trim(),
      tx_comm: formValues.tx_comm.trim(),
      created_at: new Date(),
    });

    let config = {
      method: "POST",
      maxBodyLength: Infinity,
      body: data,
      redirect: "follow",
      headers: {
        'Content-Type': 'application/json'
      },
    };

    const route = `${process.env.REACT_APP_BASE_URL}/api/v1/taxi/register/`;
    fetch(route, config)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          swal("Successfully Registered", result.message, "success");
        } else {
          swal("Error", result.message, "error");
        }
      })
      .catch((error) => swal("Error", error, "error"));
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Taxi Services | Convenient and Affordable Rides</title>
          <meta name="description" content="Book a taxi with ease. We offer affordable and reliable taxi services for your daily commute, airport transfers, and more. Ride with us today!" />
          <meta name="keywords" content="Taxi, Cab Service, Airport Taxi, Local Taxi, Outstation Taxi, Book Taxi Online" />
          <meta property="og:title" content="Taxi Services | Convenient and Affordable Rides" />
          <meta property="og:description" content="Book a taxi with ease. We offer affordable and reliable taxi services for your daily commute, airport transfers, and more. Ride with us today!" />
          <meta property="og:type" content="website" />
        </Helmet>

        <section className="taxi-text" role="main">
          <div className="taxi1">
            <article className="taxi2">
              <header>
                <h1 className="text">1. B2C (Business to Customer)</h1>
                <h2 className="text1">a) Hire-Car Taxi</h2>
              </header>
              <div className="car_taxi">
                <div className="form" style={{ margin: "auto" }}>
                  <form onSubmit={handleSubmit} autoComplete="off" aria-label="Taxi Booking Form">

                    <div className="buttons">
                      <select onChange={handleChange} style={{ background: "#FFF", padding: "5px", fontWeight: "600", border: "1px solid #FFA3A3" }} name="tx_type" className="dropdown_taxi" aria-label="Select Taxi Type">
                        <option value="Airport">Airport</option>
                        <option value="Local">Local</option>
                        <option value="Out Station">Out Station</option>
                      </select>

                      <select onChange={handleChange} style={{ background: "#FFF", padding: "5px", fontWeight: "600", border: "1px solid #FFA3A3" }} name="tx_trip_type" className="dropdown_taxi" aria-label="Select Trip Type">
                        <option value="One-Way Trip">One-Way Trip</option>
                        <option value="Round Trip">Round Trip</option>
                      </select>

                      <select onChange={handleChange} style={{ background: "#FFF", padding: "5px", fontWeight: "600", border: "1px solid #FFA3A3" }} name="tx_return_type" className="dropdown_taxi" aria-label="Select Return Type">
                        <option value="Hatch back">Hatch back</option>
                        <option value="Sedan">Sedan</option>
                        <option value="SUV">SUV</option>
                        <option value="Luxury">Luxury</option>
                      </select>
                    </div>

                    <div className="main_form">
                      {/* Input fields with proper labels and placeholder */}
                      {[
                        { label: 'First name', name: 'tx_fname', placeholder: 'Enter First Name' },
                        { label: 'Last name', name: 'tx_lname', placeholder: 'Enter Last Name' },
                        { label: 'Drop address', name: 'tx_drop_add', placeholder: 'Enter Drop Address' },
                        { label: 'Pickup address', name: 'tx_pick_add', placeholder: 'Enter Pickup Address' },
                      ].map((input) => (
                        <div key={input.name}>
                          <label className="label_taxi" htmlFor={input.name}>{input.label}</label>
                          <input
                            type="text"
                            onChange={handleChange}
                            style={{ background: "#FFF", padding: "5px", fontWeight: "600", border: "1px solid #FFA3A3" }}
                            id={input.name}
                            name={input.name}
                            placeholder={input.placeholder}
                          />
                        </div>
                      ))}
                      
                      {/* Date and time inputs */}
                      {[
                        { label: 'Pickup date', name: 'tx_pick_date', type: 'date' },
                        { label: 'Drop date', name: 'tx_drop_date', type: 'date' },
                        { label: 'Pickup time', name: 'tx_pick_time', type: 'time' },
                        { label: 'Drop time', name: 'tx_drop_time', type: 'time' },
                      ].map((input) => (
                        <div key={input.name}>
                          <label className="label_taxi" htmlFor={input.name}>{input.label}</label>
                          <input
                            type={input.type}
                            onChange={handleChange}
                            style={{ background: "#FFF", padding: "5px", fontWeight: "600", border: "1px solid #FFA3A3" }}
                            id={input.name}
                            name={input.name}
                          />
                        </div>
                      ))}

                      {/* Mobile number and email inputs */}
                      {[
                        { label: 'Mobile number', name: 'tx_mob_num', placeholder: 'Enter Mobile Number', type: 'text', minLength: 10, maxLength: 10 },
                        { label: 'Email', name: 'tx_email', placeholder: 'Enter Email', type: 'email' },
                      ].map((input) => (
                        <div key={input.name}>
                          <label className="label_taxi" htmlFor={input.name}>{input.label}</label>
                          <input
                            type={input.type}
                            onChange={handleChange}
                            style={{ background: "#FFF", padding: "5px", fontWeight: "600", border: "1px solid #FFA3A3" }}
                            id={input.name}
                            name={input.name}
                            placeholder={input.placeholder}
                            minLength={input.minLength}
                            maxLength={input.maxLength}
                          />
                        </div>
                      ))}

                      <div className="full-width">
                        <label className="label_taxi" htmlFor="tx_comm">Comment</label>
                        <textarea
                          onChange={handleChange}
                          style={{ height: "130px", background: "#FFF", padding: "5px", fontWeight: "600", border: "1px solid #FFA3A3" }}
                          id="tx_comm"
                          name="tx_comm"
                          placeholder="Enter Your Comments"
                        ></textarea>
                      </div>

                      
                      <button type="submit" className="full-width" id="btn_taxi">
                        Submit
                      </button>
                     
                    </div>
                  </form>
                </div>
              </div>
              <div className="text3">
                <p className="text1">b) Hire-Auto taxi </p>
                <p>
                  <ol style={{ textAlign: "start" }} className="ol">
                    <li>
                      Download the RUBIA App
                      <ul type="disc">
                        <li>Available on the App Store and Google Play.</li>
                        <li>Download for iOS | Download for Android</li>
                      </ul>
                    </li>
                  </ol>
                </p>
              </div>
              <div className="text3">
                <p className="text1">c) Hire-Bike taxi </p>
                <p>
                  <ol className="ol" style={{ textAlign: "start" }}>
                    <li>
                      Download the RUBIA App
                      <ul type="disc">
                        <li>Available on the App Store and Google Play.</li>
                        <li>Download for iOS | Download for Android</li>
                      </ul>
                    </li>
                  </ol>
                </p>
              </div>
            </article>
          </div>
        </section>
      </HelmetProvider>
    </>
  );
};

export default Taxi;




