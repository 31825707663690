import React, { useState } from "react";
import "../PrivateServices/PrivateServices.css";
import images1 from "./importAllImages1";


const PrivateServices = ({ handleClick }) => {
  const [title, setTitle] = useState("view more  \u2193");  
  const [activeButton, setActiveButton] = useState(null);
  const [activeSubtitle,setActiveSubtitle]=useState("Services")
  const [noOfElements, setElements] = useState(48);

  const toggle = () => {
    if (noOfElements >= images1.length) {
      setTitle("view more \u2193");
      setElements(48);
    } else {
      setElements(noOfElements + 48);
      if (noOfElements + 48 >= images1.length) {
        setTitle("view less \u2191");
      }
    }
  };
  const handleButtonClick = (index, name) => {
    setActiveButton(index);
    setActiveSubtitle(name);
  };
 
  const slice = images1.slice(0, noOfElements);
  return (
    <>
    <div>

    
    <div className="header_pvt">
    Private <span style={{color:"#C68643",fontWeight:"700"}}>Organizations :</span>
    </div>
      <div id="pvt_container">
        <div className="pvt_sub_container">
        {slice.map((img, index) => (
            <form action="" key={index}>
              <div
                className="pvt_labels"
                key={index}
                onClick={() => handleButtonClick(index,img.name)}
                style={{
                  backgroundColor:
                    activeButton === index ? "#650000" : "#FAF2EB",
                }}
              >
                <div id="pvt_img">
                  <img className="pvt_img" src={img.image} alt="" />
                </div>
                <div
                  className="pvt_text"
                  key={index}
                  style={{
                    color: activeButton === index ? "white" : "#650000",
                  }}
                >
                  {img.name}
                </div>
              </div>
            </form>
          ))}
        </div>
        <button type="button" className="view" onClick={toggle}>
        {title}
      </button>
      </div>
     
      <br />
      
     
      <div className="header_b1">
            {" "}
            {activeSubtitle}{" "}
            <span style={{ color: "#C68643" }}>in Hyderabad :</span>{" "}
          </div>
     
      </div>
    
    </>
  );
};

export default PrivateServices;
