

const importAllImages = (r) => {
    return r.keys().map((assets) => {
      const name = assets.replace('./', '').replace(/\.[^/.]+$/, ""); // Extract name without extension
      return {
        name: name,
        image: r(assets)
      };
    });
  };
  
  const images = importAllImages(require.context('./assets', false, /\.(png|jpe?g|svg)$/));
  
  export default images;
  