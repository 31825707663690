import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import img2 from "../assets/WhatsApp Image 2024-08-26 at 2.47.38 PM.jpeg";
import img3 from "../assets/WhatsApp Image 2024-08-26 at 2.51.05 PM (1).jpeg";
import bedrms from "../assets/Group 164.svg";
import baths from "../assets/2id76bdb6ohtRKvV28eLMbDbXzx 1.svg";
import car from "../assets/ph_car-thin.svg";
import furnisher from "../assets/2idEg1CR1iYh0n8O1wyPSXKL5ms 1.svg";
import sqfts from "../assets/2iYTSNCTfi5e8smZsPjCy3ergB7 1.svg";
import searchIcon from "../assets/Vector.svg";
import LocationIcon from "../assets/weui_location-outlined.svg";
import img1 from "../assets/WhatsApp Image 2024-08-26 at 2.51.05 PM.jpeg";
// import Sell from "./Pages/Sell";

const Main = () => {
  const [selected1, setSelected1] = useState("");
  const [minValue, setMinValue] = useState(0);
  const [showDropdown, setShowDropdown] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [maxValue, setMaxValue] = useState(50000000); // ₹5cr is 50,000,000
  const handleClick = (dropdown) => {
    setSelected1(dropdown);
    toggleDropdown(dropdown);
  };

  const getArrowDirection = (dropdown) => {
    return showDropdown === dropdown ? "▲" : "▼";
  };
  const toggleDropdown = (dropdown) => {
    setShowDropdown(showDropdown === dropdown ? "" : dropdown);
  };
  const handleSelection = (value) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((option) => option !== value)
        : [...prevSelected, value]
    );
  };
  const handleMinSliderChange = (e) => {
    const value = Number(e.target.value);
    if (value < maxValue) {
      setMinValue(value);
    } else {
      alert("Min value must be less than Max value.");
    }
  };

  const handleMaxSliderChange = (e) => {
    const value = Number(e.target.value);
    if (value > minValue) {
      setMaxValue(value);
    } else {
      alert("Max value must be greater than Min value.");
    }
  };

  const handleMinInputChange = (e) => {
    const value = Number(e.target.value);
    if (value < maxValue) {
      setMinValue(value);
    } else {
      alert("Min value must be less than Max value.");
    }
  };

  const handleMaxInputChange = (e) => {
    const value = Number(e.target.value);
    if (value > minValue) {
      setMaxValue(value);
    } else {
      alert("Max value must be greater than Min value.");
    }
  };
  const renderOptions = (options) => {
    return options.map((option) => (
      <div
        key={option.value}
        className={`custom-option ${
          selectedOptions.includes(option.value) ? "selected" : ""
        }`}
        onClick={() => handleSelection(option.value)}
      >
        <input
          type="checkbox"
          style={{ width: "18px", height: "25px", borderRadius: "50px" }}
          checked={selectedOptions.includes(option.value)}
          onChange={() => handleSelection(option.value)}
          onClick={(e) => e.stopPropagation()}
        />
        <label>{option.label}</label>
      </div>
    ));
  };
  const properties = [
    {
      id: 1,
      name: "Unnati Shree Rath Apartments",
      location: "Nizampet, Hyderabad",
      imgUrls: [img1, img2, img3],
      bedrooms: 4,
      bathrooms: 3,
      parking: 1,
      furnishing: "Unfurnished",
      transactionType: "New Property",
      status: "Ready to Move",
      superArea: "2079 sqft",
      price: "4.94Cr",
    },
    {
        id: 1,
        name: "dream11 Apartments",
        location: "Erragadda, Hyderabad",
        imgUrls: [img1, img2, img3],
        bedrooms: 4,
        bathrooms: 6,
        parking: 2,
        furnishing: "Unfurnished",
        transactionType: "new Property",
        status: "Ready to Move",
        superArea: "250 sqft",
        price: "1.94cr",
      },
    
  ];
  return (
    <>
      <div className="RealPortal_searchcontainer1">
        <div className="RealPortalsearchtext">
          Find a home away from <span>Home</span>
        </div>
        <div className="searchinput_container">
          <img src={searchIcon} alt="Search Icon" />
          <input
            type="text"
            placeholder="Search"
            className="RealPortal_searchinput1"
          />
          <div className="vertical_line"></div>
          <img src={LocationIcon} alt="Location Icon" />
          <input
            type="text"
            className="RealPortal_searchinput2"
            placeholder="Location"
          />
          <button className="searchbtn_RealPortal">Search</button>
        </div>
      </div>
      <div className="RealPortal_searchcontainer">
        <div className="dropdowncontainer">
          <div className="dropdown">
            <button
              className={`dropdown-btn ${
                selected1 === "PropertyTypes" &&
                showDropdown === "PropertyTypes"
                  ? "active"
                  : ""
              }`}
              onClick={() => handleClick("PropertyTypes")}
            >
              Property Types {getArrowDirection("PropertyTypes")}
            </button>
            {showDropdown === "PropertyTypes" && (
              <div className="dropdown-content">
                <div className="dropdown-section">
                  <h4>Residential</h4>
                  {renderOptions([
                    {
                      label: "Independent House",
                      value: "independent-house",
                    },
                    { label: "Duplex House", value: "duplex-house" },
                    { label: "Triplex House", value: "triplex-house" },
                    {
                      label: "Independent Flats",
                      value: "independent-flats",
                    },
                    { label: "Duplex Flats", value: "duplex-flats" },
                    { label: "Triplex Flats", value: "triplex-flats" },
                  ])}
                </div>
                <div className="dropdown-section">
                  <h4>Commercial</h4>
                  {renderOptions([
                    {
                      label: "Independent House",
                      value: "independent-house1",
                    },
                    { label: "Duplex House", value: "duplex-house2" },
                    { label: "Triplex House", value: "triplex-house3" },
                  ])}
                  <div>
                    <h4>Plots</h4>
                    {renderOptions([{ label: "Plots", value: "Plots" }])}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="dropdown">
            <button
              className={`dropdown-btn ${
                selected1 === "BHK" && showDropdown === "BHK" ? "active" : ""
              }`}
              onClick={() => handleClick("BHK")}
            >
              BHK {getArrowDirection("BHK")}
            </button>
            {showDropdown === "BHK" && (
              <div className="dropdown-content1">
                <div className="dropdown-section">
                  <h4>BHK</h4>
                  <div className="filter_anchortags_bhk">
                    {renderOptions([
                      { label: "1 BHK", value: "1 BHK" },
                      { label: "2 BHK", value: "2 BHK" },
                      { label: "3 BHK", value: "3 BHK" },
                    ])}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="dropdown">
            <button
              className={`dropdown-btn ${
                selected1 === "Budget" && showDropdown === "Budget"
                  ? "active"
                  : ""
              }`}
              onClick={() => handleClick("Budget")}
            >
              Budget {getArrowDirection("Budget")}
            </button>
            {showDropdown === "Budget" && (
              <div className="dropdown-content2">
                <div className="dropdown-section">
                  <h4>Budget</h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <input
                      type="number"
                      placeholder="min"
                      value={minValue}
                      onChange={(e) => setMinValue(Number(e.target.value))}
                      className="minindector"
                    />
                    <input
                      type="number"
                      value={maxValue}
                      onChange={(e) => setMaxValue(Number(e.target.value))}
                      className="maxindector"
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>Min: ₹{minValue}</label>
                    <input
                      type="range"
                      min="0"
                      max="50000000"
                      value={minValue}
                      onChange={handleMinInputChange}
                      className="min-slider"
                      step="100000"
                    />
                    <label>Max: ₹{maxValue}</label>
                    <input
                      type="range"
                      min="0"
                      max="50000000"
                      value={maxValue}
                      onChange={handleMaxInputChange}
                      className="max-slider"
                      step="100000"
                    />
                  </div>
                  <button
                    className="Budgetbtn"
                    onClick={() =>
                      alert(`Min: ₹${minValue}, Max: ₹${maxValue}`)
                    }
                  >
                    Done
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="dropdown">
            <button
              className={`dropdown-btn ${
                selected1 === "PostedBy" && showDropdown === "PostedBy"
                  ? "active"
                  : ""
              }`}
              onClick={() => handleClick("PostedBy")}
            >
              Posted By {getArrowDirection("PostedBy")}
            </button>
            {showDropdown === "PostedBy" && (
              <div className="dropdown-content3">
                <div className="dropdown-section">
                  <h4>Posted By</h4>
                  {renderOptions([
                    { label: "Owner", value: "Owner" },
                    { label: "Agent", value: "Agent" },
                    { label: "Builder", value: "Builder" },
                  ])}
                </div>
              </div>
            )}
          </div>

          <div className="dropdown">
            <button
              className={`dropdown-btn ${
                selected1 === "Filter" && showDropdown === "Filter"
                  ? "active"
                  : ""
              }`}
              onClick={() => handleClick("Filter")}
            >
              Filter {getArrowDirection("Filter")}
            </button>
            {showDropdown === "Filter" && (
              <div className="dropdown-content4">
                <div className="dropdown-section">
                  <h4>Covered Area</h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <input
                      type="number"
                      placeholder="min"
                      value={minValue}
                      onChange={(e) => setMinValue(Number(e.target.value))}
                      className="minindector"
                    />
                    <input
                      type="number"
                      value={maxValue}
                      onChange={(e) => setMaxValue(Number(e.target.value))}
                      className="maxindector"
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      type="range"
                      min="0"
                      max="50000000"
                      value={minValue}
                      onChange={handleMinInputChange}
                      className="min-slider"
                      step="100000"
                    />
                    <input
                      type="range"
                      min="0"
                      max="50000000"
                      value={maxValue}
                      onChange={handleMaxSliderChange}
                      className="max-slider"
                      step="100000"
                    />
                  </div>
                  <button
                    className="Budgetbtn"
                    onClick={() =>
                      alert(`Min: ₹${minValue}, Max: ₹${maxValue}`)
                    }
                  >
                    Done
                  </button>
                </div>

                <div className="dropdown-section">
                  <h4>Possession status</h4>
                  <div className="filter_anchortags_filter">
                    {renderOptions([
                      { label: "Ready To Move", value: "Ready To Move" },
                      {
                        label: "Under construction",
                        value: "Under construction",
                      },
                      { label: "New Launch", value: "New Launch" },
                    ])}
                  </div>
                </div>
                <div className="dropdown-section">
                  <h4>Purchase type</h4>
                  <div className="filter_anchortags_filter">
                    {renderOptions([
                      { label: "Resale", value: "Resale" },
                      { label: "New Booking", value: "New Booking" },
                    ])}
                  </div>
                </div>
                <div className="dropdown-section">
                  <h4>Furnishing</h4>
                  <div className="filter_anchortags_filter">
                    {renderOptions([
                      { label: "Furnishing", value: "Furnishing" },
                      {
                        label: "Semi-Furnishing",
                        value: "Semi-Furnishing",
                      },
                      { label: "Unfurnishing", value: "Unfurnishing" },
                    ])}
                  </div>
                </div>
                <div className="dropdown-section">
                  <h4>Amenities</h4>
                  <div className="filter_anchortags_filter">
                    {renderOptions([
                      { label: "Parking", value: "Parking" },
                      { label: "PowerBackup", value: "PowerBackup" },
                      { label: "Lift", value: "Lift" },
                      { label: "Park", value: "Park" },
                      { label: "Swimming Pool", value: "Swimming Pool" },
                      { label: "Club House", value: "Club House" },
                      {
                        label: "Security Person",
                        value: "Security Person",
                      },
                    ])}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
      {properties.map((property) => (
        <div className="realestate-cardcontainer" key={property.id}>
          <article className="propertyimg-container">
            <Carousel
              autoPlay={1000}
              infiniteLoop={true}
              dynamicHeight={false}
              swipeable={false}
              axis="horizontal"
            >
              {property.imgUrls.map((url, index) => (
                <div id="carouselimg-container" key={index}>
                  <img
                    src={url}
                    id="carousel-img"
                    alt={`${property.name} image ${index + 1}`}
                  />
                </div>
              ))}
            </Carousel>
          </article>

          <section className="propertydetails-container">
            <header className="propertynamelocation-container">
              <h2 className="propertyname">{property.name}</h2>
              <h3 className="propertylocation">{property.location}</h3>
            </header>

            <section className="property-typedetails">
              <p className="types">
                <img src={bedrms} alt="Number of bedrooms" />
                {property.bedrooms} Beds
              </p>
              <p className="types">
                <img src={baths} alt="Number of bathrooms" />
                {property.bathrooms} Baths
              </p>
              <p className="types">
                <img src={car} alt="Covered parking available" />
                {property.parking} Covered Parking
              </p>
              <p className="types">
                <img src={furnisher} alt="Furnishing status" />
                {property.furnishing}
              </p>
            </section>

            <aside className="documentation">
              <div className="documentationtypes">
                <p className="documention-header">Transaction Type</p>
                <p className="propertype">{property.transactionType}</p>
              </div>
              <div className="documentationtypes">
                <p className="documention-header">Status</p>
                <p className="propertype">{property.status}</p>
              </div>
              <div className="documentationtypes">
                <p className="documention-header">Super Area</p>
                <p className="propertype">{property.superArea}</p>
              </div>
            </aside>

            <div className="propertybtn-container">
              <div className="property-price">
                <div className="price">
                  <span style={{ color: "#C58643" }}>₹</span> {property.price}
                </div>
                <div className="vertical_line" id="vertical_line1"></div>
                <div className="sqfts">
                  <img src={sqfts} alt="Property area in square feet" />
                  {property.superArea}
                </div>
              </div>
              <div className="property-contact">
                <button className="sharebtn">Share</button>
                <button className="contactbtn">Contact</button>
              </div>
            </div>
          </section>
        </div>
        ))}
        </div>
    </>
  );
};

export default Main;
