import React from 'react';
import Services from "../Services/Services"
const Middlecontent = () => {
  return (
    <>
    <div class="main-text ">
        <div className='middle1'>
          <Services/>
        </div>
      </div>
    </>
  )
}

export default Middlecontent