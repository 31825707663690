import React, { useState } from 'react';
import "./GovermentServices.css";
import images from './importAllImages'; // This imports all images as an array of objects

const GovermentServices = () => {
  const [title1, setTitle1] = useState("view more \u2193");
  const [activeButton, setActiveButton] = useState(null);
  const [activeSubtitle,setActiveSubtitle]=useState("Services")
  const [noOfElements, setElements] = useState(48);

  const toggle = () => {
    if (noOfElements >= images.length) {
      setTitle1("view more \u2193");
      setElements(48);
    } else {
      setElements(noOfElements + 48);
      if (noOfElements + 48 >= images.length) {
        setTitle1("view less \u2191");
      }
    }
  };

  const handleButtonClick = (index,name) => {
    setActiveButton(index);
    setActiveSubtitle(name);
  };

  const slice = images.slice(0, noOfElements);

  return (
    <>
      <div className='header_govt'>
        Government <span style={{ color: "#C68643", fontWeight: "700" }}>Organizations :</span>
      </div>
      <div className='govt_container'>
        <div className='govt_sub_container' style={{ textAlign: "center" }}>
          {slice.map((img, index) => (
            <form action="" key={index}>
              <div
                className='govt_lables'
                onClick={() => handleButtonClick(index,img.name)}
                style={{
                  backgroundColor: activeButton === index ? "#144273" : "#FAF2EB",
                }}
              >
                <div>
                  <img src={img.image} alt={img.name} id='govt_img' />
                </div>
                <div
                  className='govt_text'
                  style={{
                    color: activeButton === index ? "white" : "#650000",
                  }}
                >
                  {img.name}
                </div>
              </div>
            </form>
          ))}
        </div>
        <button type="button" className="view" onClick={toggle}>
          {title1}
        </button>
      </div>
      <br />
      <div className="header_b1">
            {" "}
            {activeSubtitle}{" "}
            <span style={{ color: "#C68643" }}>in Hyderabad :</span>{" "}
          </div>
    </>
  );
};

export default GovermentServices;
