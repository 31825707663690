import React, { useState } from 'react';
import axios from 'axios';

const Contact1 = () => {
    const [sname, setSname] = useState('');
    const [cname, setCname] = useState('');
    const [cemail, setCemail] = useState('');
    const [cmobile, setCmobile] = useState('');
    const [comment, setComment] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
      e.preventDefault();

      try {
        const formData = new URLSearchParams();
        formData.append('sname', sname);
        formData.append('cname', cname);
        formData.append('cemail', cemail);
        formData.append('cmobile', cmobile);
        formData.append('comment', comment);

        const response = await axios.post('http://localhost:8080/creatapi/Rubia/creat.php', formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });

        if (response.data.status === 'success') {
          setMessage('Form submitted successfully');
          setSname('');
          setCname('');
          setCemail('');
          setCmobile('');
          setComment('');
        } else {
          setMessage(response.data.message || 'An error occurred');
        }
      } catch (error) {
        setMessage('An error occurred');
      }
    };

    return (
        <div style={{ height: "100%", marginTop: "10px" }} className="main-contact">
          <div className="contact1">
            <h1>Submit Your Details</h1>
            <form onSubmit={handleSubmit}>
              <div>
                <label>Your Name:</label>
                <input
                  type="text"
                  value={sname}
                  onChange={(e) => setSname(handleSubmit)}
                  required
                />
              </div>
              <div>
                <label>Company Name:</label>
                <input
                  type="text"
                  value={cname}
                  onChange={(e) => setCname(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Email:</label>
                <input
                  type="email"
                  value={cemail}
                  onChange={(e) => setCemail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Mobile:</label>
                <input
                  type="text"
                  value={cmobile}
                  onChange={(e) => setCmobile(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Comment:</label>
                <textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  required
                />
              </div>
              <button type="submit">Submit</button>
            </form>
            {message && <p>{message}</p>}
          </div>
        </div>
    );
};

export default Contact1;
