import React from 'react';
import Ad1 from "./assets/WhatsApp Image 2024-07-29 at 17.12.09_f4954411.jpg";
import Ad2 from "./assets/Hire professional Driver squer.png";
import Ad3 from "./assets/Instagram post - 3.jpg";
import Ad4 from "./assets/Instagram_post_-_2_(2)[1].png";

const RightSide = () => {
  return (
    <div className="ads">
      <div className="ads-container">
        <div className="ads1"><img className="Adimg" src={Ad1} alt="Ad 1" /></div>
        <div className="ads1"><img className="Adimg" src={Ad2} alt="Ad 2" /></div>
        <div className="ads1"><img className="Adimg" src={Ad3} alt="Ad 3" /></div>
        <div className="ads1"><img className="Adimg" src={Ad4} alt="Ad 4" /></div>

        {/* Duplicate images for smooth scrolling */}
        <div className="ads1"><img className="Adimg" src={Ad1} alt="Ad 1" /></div>
        <div className="ads1"><img className="Adimg" src={Ad2} alt="Ad 2" /></div>
        <div className="ads1"><img className="Adimg" src={Ad3} alt="Ad 3" /></div>
        <div className="ads1"><img className="Adimg" src={Ad4} alt="Ad 4" /></div>
      </div>
    </div>
  );
};

export default RightSide;
