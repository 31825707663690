import React, { useState } from 'react';
// import ReadMoreReact from 'read-more-react';
import "./Home.css";
import Profileimg from "../assets/Rectangle 49.png";
import Sampras from "../assets/Group 104.png"
import SamprasProfile from "../assets/Rectangle 49 (1).png"
import Postimg from "../assets/Rectangle 51.png";
import LikeLogo from "../assets/LikeLogo.svg";
import CommentLogo from "../assets/Commentlogo.svg";
import ShareLogo from "../assets/ShareLogo.svg";
import SaveLogo from "../assets/SaveLogo.svg";
import Default from "../assets/profile.png";
import FrontendPoster from "../assets/layoutdesigner_online_3.jpg"
import Flutter1 from "../assets/Online-Exclusive-3-2_jpg-2-scaled-1-Sep-25-2023-10-18-57-9646-AM.jpg"
 import Frontendprofile from "../assets/dfa86b49484f98d6cc6e07ea54803fed.jpg"
 import Vasu from "../assets/IMG_20240629_172037_527.jpg"
 import Balu from "../assets/WhatsApp Image 2024-08-23 at 9.58.02 AM.jpeg"
 import Flutter from "../assets/flutter-developer1.jpg"

const Home = () => {
  const [like, setLike] = useState(0);
  const [comment, setComment] = useState(0);
  const [follow, setFollow] = useState("follow");

  const toggleFollow = () => {
    setFollow((prevFollow) => (prevFollow === "follow" ? "unfollow" : "follow"));
  };

  const JobportalHomePost = [
    {
      name: "Veera Sudhakar Rao",
      designation: "Rubia.services Recruiter",
      description: "Remote Internship🚨",
      profileimg: Profileimg,
      postimg: Postimg,
    },
    {
      name: "Sampras singh",
      designation: "UI/UX Designer",
      description: "Rubia services private limited",
      profileimg: SamprasProfile,
      postimg: Sampras,
    },
   
    {
      name: "Shaik Javeed",
      designation: "FrontEnd developer(React JS)",
      description: "Rubia services private limited",
      profileimg: Frontendprofile,
      postimg: FrontendPoster,
    },
    {
      name: "Balu",
      designation: "Flutter developer",
      description: "Rubia services private limited",
      profileimg: Balu,
      postimg: Flutter1,
    },
    {
      name: "Vasu venkat",
      designation: "Flutter developer",
      description: "Rubia services private limited",
      profileimg: Vasu,
      postimg: Flutter,
    },

  ];

  return (
    <>
      {JobportalHomePost.map((e, index) => (
        <div className='poster_container' key={index}>
          <div className='poster_subcontainer1'>
            <div className='minicontainer1'>
              <div className='profileimg'>
                <img src={e.profileimg} alt="Profile" />
              </div>
              <div className='postdetails'>
                <div className='name'>{e.name}</div>
                <div className='designation'>{e.designation}</div>
                <div className='description'>{e.description}</div>
              </div>
            </div>
            <div className='follow'>
              <div></div>
              <div>
                <button style={{color:"#144273"}} className='HomeFollowbtn' onClick={toggleFollow}>{follow}</button>
              </div>
            </div>
          </div>
          <div className='poster_subcontainer2'>
            <img src={e.postimg} alt="Post" />
          </div>
          <div className='poster_subcontainer3'>
            <div className='Likes_comment_count'>
              <div className='countoflikes'>{like} likes</div>
              <div className='countofcomments'>{comment} comments</div>
            </div>
            <div className='verticalline'></div>
            <div className='Likes_comment_share_save'>
              <div className='like'>
                <button className='mediaicons' onClick={() => setLike(like + 1)}>
                  <img src={LikeLogo} alt="Like" /> Like
                </button>
              </div>
              <div className='comment'>
                <button className='mediaicons' onClick={() => setComment(comment + 1)}>
                  <img src={CommentLogo} alt="Comment" /> Comment
                </button>
              </div>
              <div className='share'>
                <button className='mediaicons'>
                  <img src={ShareLogo} alt="Share" /> Share
                </button>
              </div>
              <div className='save'>
                <button className='mediaicons'>
                  <img src={SaveLogo} alt="Save" /> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default Home;
