import React from "react";
import { Link } from "react-router-dom";
import Twitter from "./assets/skill-icons_twitter.png";
import Facebook from "./assets/akar-icons_facebook-fill.png";
import LinkedIn from "./assets/skill-icons_linkedin.png";
import Instagram from "./assets/skill-icons_instagram.png";

const Footer = () => {
  return (
    <>
   
      <br />
      <br />
      <footer id="footer">
        <div className="footer_container1">
          <div className="footer_content" id="footer_content1">
            <u style={{ color: "#C58643" }}>
              <b>Info</b>
            </u>
            <div className="anchor1" style={{ marginTop: "8px" }}>
              <Link to='/TermsConditions/'>Terms & Conditions</Link>
              <Link to='/RefundPolicy/'>Refund Policy</Link>
               <Link to='/Privacypolicy/'>Privacy Policy</Link>
            </div>
          </div>
         
          <div className="footer_content" id="footer_content1">
            <u style={{ color: "#C58643" }}>
              <b>Address</b>
            </u>
            <div className="address" style={{ marginTop: "15px" }}>
              Registered: 6-1-105, Saifabad, Khairatabad, Hyderabad-500004.
            </div>
            <div className="address" style={{ marginTop: "20px" }}>
              Branch: Metro Pillar No. 974, Flat No. 107, Rams Enclave, Vikaspuri, Erragadda, Hyderabad - 500018.
            </div>
          </div>

          <div className="footer_content" id="footer_contact">
            <u style={{ color: "#C58643" }}>
              <b>Contact</b>
            </u>
            <p></p>
            <div>
              +91 78936 53899 
              <br />
              <br />
              <p style={{textTransform:"lowercase"}}>info@rubia.services</p>
            </div>
            <div className="footer-socialmedia">
              <a href="https://x.com/RubiaServices" target="blank">
                <img className="socialmedia-icons" src={Twitter} alt="Twitter" />
              </a>
              <a href="https://m.facebook.com/profile.php?id=61550853734341&mibextid=ZbWKwL" target="blank">
                <img className="socialmedia-icons" src={Facebook} alt="Facebook" />
              </a>
              <a href="https://in.linkedin.com/company/rubiaservices" target="blank">
                <img className="socialmedia-icons" src={LinkedIn} alt="LinkedIn" />
              </a>
              <a href="https://www.instagram.com/rubia.services/?igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="blank">
                <img className="socialmedia-icons" src={Instagram} alt="Instagram" />
              </a>
            </div>
          </div>
          
        </div>
      </footer>
    </>
  );
};

export default Footer;
