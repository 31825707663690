import React from "react";
import "./MyNetworks.css";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Searchicon from "../assets/Vector.svg";
import Voice from "../assets/material-symbols_mic.svg";
import CoverPage from "../assets/Rectangle 145.svg";
import Profilepick from "../assets/Ellipse 18.svg";
import CoverPage2 from "../assets/Rectangle 80 (1).svg";
import Followers from "../assets/fluent_people-16-regular.svg";
import Following from "../assets/simple-line-icons_user-following.svg"
import Groups from "../assets/fluent-mdl2_group.svg"
import Hashtags from "../assets/Hashtag.svg"

const MyNetworks = () => {
  const jobfriendprofile = [
    {
      CoverPage: CoverPage,
      Profilepick: Profilepick,
      Profilename: "Sampras Singh",
      Profilejob: "Junior UI/UX Designer"
    },
    {
      CoverPage: CoverPage2,
      Profilepick: Profilepick,
      Profilename: "Shaik Javeed",
      Profilejob: "FrontEnd Developer"
    }
  ];

  const options0 = [
    { value: 'Followers', label: <span style={{color:"#144273"}}><img src={Followers} alt="" className="dropdown-icon" /> Followers</span> }
  ];
  const options1 = [
    { value: 'Following', label: <span style={{color:"#144273"}}><img src={Following} alt="" className="dropdown-icon" /> Following</span> }
  ];
  const options2 = [
    { value: 'Groups', label: <span style={{color:"#144273"}}><img src={Groups} alt="" className="dropdown-icon" /> Groups</span> }
  ];
  const options3 = [
    { value: 'Hashtags', label: <span style={{color:"#144273"}}><img src={Hashtags} alt="" className="dropdown-icon" /> Hashtags</span> }
  ];

  const allOptions = [options0, options1, options2, options3];
  const defaultOptions = [
    options0[0],
    options1[0],
    options2[0],
    options3[0]
  ];

  return (
    <>
      <div className="mynetworkmain-container">
        <div className="Mynetwork_search">
          <button>
            <img src={Searchicon} alt="" />
          </button>
          <input type="search" />
          <button>
            <img src={Voice} alt="" />
          </button>
        </div>
        <div className='friendprofiledropdown'>
          {allOptions.map((options, index) => (
            <Dropdown
              key={index} // Assign a unique key to each dropdown
              options={options}
              value={defaultOptions[index].value} // Use the corresponding default option value
              placeholder="Select an option"
            />
          ))}
        </div>
        <div className="FriendsProfile-container">
          {jobfriendprofile.map((f, index) => (
            <div className="FriendsProfile-subcontainer" key={index}>
              <div className="coverimg">
                <img src={f.CoverPage} alt="" />
              </div>
              <img className="profilepick" src={f.Profilepick} alt="" />
              <div className="profiledetails">
                <div className="Profilename">{f.Profilename}</div>
                <div className="Profilejob">{f.Profilejob}</div>
              </div>
              <div>
                <button className="followbtn">Follow back</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MyNetworks;
