import React from "react";
import "../Electricity/Electricity.css";
import { HelmetProvider, Helmet } from 'react-helmet-async';

const Electricity = () => {
  const ElectricityData = [
    {
      State: "Andhra Pradesh: ",
      text:"Andhra Pradesh Eastern Power Distribution Company",
      url: "https://www.apcpdcl.in/",
    },
    {
      State: "Arunachal Pradesh:",
      text:"Arunachal Pradesh State Electricity Regulatory Commission",
      url: "http://www.arunachalpower.org.in/",
    },
    {
      State: "Assam: ",
    text:"Assam Power Distribution Company Limited",
      url: "https://www.apdcl.org/",
    },
    {
      State: "Bihar: ",
      text:"Bihar State Power Holding Company Limited",
      url: "https://www.sbpdcl.co.in/",
    },
    {
      State: "Chhattisgarh: ",
      text:"Chhattisgarh State Power Distribution Company Limited",
      url: "https://www.cspdcl.co.in/",
    },
    {
      State: "Goa: ",
      text:"Goa Electricity Department",
      url: "https://www.goaelectricity.gov.in/",
    },
    {
      State: "Gujarat: ",
      text:"Gujarat Urja Vikas Nigam Limited",
      url: "https://www.ugvcl.com/",
    },
    {
      State: "Haryana: ",
      text:"Haryana Power Utilities",
      url: "https://epayment.uhbvn.org.in/",
    },
    {
      State: "Himachal Pradesh: ",
      text:"Himachal Pradesh State Electricity Board",
      url: "https://www.hpseb.in/HPSEBOnlinePayment/index.html",
    },
    {
      State: "Jharkhand: ",
      text:"Jharkhand Bijli Vitran Nigam Limited",
      url: "https://jbvnl.co.in/",
    },
    {
      State: "Karnataka:",
      text:" Bangalore Electricity Supply Company Limited",
      url: "https://bescom.karnataka.gov.in/info-3/Online+Bill+Payment/en",
    },
    {
      State: "Kerala:",
      text:" Kerala State Electricity Board",
      url: "https://wss.kseb.in/selfservices/",
    },
    {
      State: "Madhya Pradesh: ",
     text:"Madhya Pradesh Paschim Kshetra Vidyut Vitaran Company Limited",
      url: "https://portal.mpcz.in/web/",
    },
    {
      State: "Maharashtra: ",
      text:"Maharashtra State Electricity Distribution Co. Ltd",
      url: "https://www.mahadiscom.in/en/home/",
    },
    {
      State: "Manipur: ",
      text:"Manipur State Power Distribution Company Limited",
      url: "https://billing.mspdcl.info/",
    },
    {
      State: "Meghalaya: ",
      text:"Meghalaya Power Distribution Corporation Limited",
      url: "https://www.meecl.nic.in/",
    },
    {
      State: "Mizoram: ",
      text:"Mizoram State Power Distribution Corporation Limited",
      url: "https://www.mizorampower.com/#/",
    },
    {
      State: "Nagaland: ",
      text:"Nagaland Power Department",
      url: "https://prepaid.dopn.gov.in/new/dashboard",
    },
    {
      State: "Odisha: ",
      text:"Central Electricity Supply Utility of Odisha",
      url: "https://www.tpcentralodisha.com",
    },
    {
      State: "Punjab: ",
      text:"Punjab State Power Corporation Limited",
      url: "https://www.pspcl.in/",
    },
    {
      State: "Rajasthan: ",
      text:"Rajasthan Rajya Vidyut Prasaran Nigam Limited",
      url: "https://www.billdesk.com/pgidsk/pgmerc/jvvnljp/JVVNLJPDetails.jsp",
    },
    {
      State: "Sikkim: ",
     text:"Sikkim Power Development Corporation Limited",
      url: "https://sikkimpower.co.in/Qbilpay.php",
    },
    {
      State: "Tamil Nadu:",
      text:" Tamil Nadu Generation and Distribution Corporation",
      url: "https://www.tangedco.org/en/tangedco/",
    },
    {
      State: "Telangana: ",
      text:"Telangana State Southern Power Distribution Company Limited",
      url: "https://tgsouthernpower.org/paybillonline",
    },
    {
      State: "Tripura:",
      text:" Tripura State Electricity Corporation Limited",
      url: "https://www.bidyutbandhu.com/custumerLoginPage",
    },
    {
      State: "Uttar Pradesh: ",
      text:"Uttar Pradesh Power Corporation Limited",
      url: "https://uppcl.org/uppcl/hi/",
    },
    {
      State: "Uttarakhand: ",
      text:"Uttarakhand Power Corporation Limited",
      url: "https://www.mobikwik.com/upcl-uttarakhand-electricity-online-bill-payment",
    },
    {
      State: "West Bengal: ",
      text:"West Bengal State Electricity Distribution Company Limited",
      url: "https://www.wbsedcl.in/irj/go/km/docs/internet/new_website/Home.html#",
    },
  ];

  return (
    <HelmetProvider>
      <Helmet>
        <title>Pay Your Electricity Bill Online | Fast & Secure Bill Payment Services</title>
        <meta
          name="description"
          content="Easily pay your electricity bill online with our secure and user-friendly platform. Enjoy hassle-free transactions, multiple payment options, and instant confirmation. Stay on top of your bills without the need to visit payment centers."
        />
      </Helmet>
      <section className="EC">
        <header className="EC1">
          <h2 className="header">Pay Electricity / Power Bill :</h2>
          <div className="Electricity-container">
            {ElectricityData.map((s, index) => (
              <article key={index} className="Electricity-item">
                <div className="Electricity-states">
                  <strong>{s.State}</strong>
                  <div>{s.text}</div>
                </div>
                <div className="Electricity-Links">
                  <a
                    href={s.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`Pay your electricity bill for ${s.State}`}
                  >
                    PAY NOW
                  </a>
                </div>
              </article>
            ))}
          </div>
        </header>
      </section>
    </HelmetProvider>
  );
};

export default Electricity;
