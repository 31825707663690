import React, { useState } from "react";
import SellPage from "./Pages/Sell";
import Main from "./Pages/Main";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { HelmetProvider, Helmet } from "react-helmet-async";
import BuyIcon from "./assets/Group 91 (1).svg";
import RentIcon from "./assets/Group 92.svg";
import SellIcon from "./assets/Group 93.svg";
import HomeloanIcon from "./assets/Group 94.svg";
import PGIcon from "./assets/fluent-emoji-high-contrast_office-building.svg";
import HelpIcon from "./assets/mingcute_service-fill.svg";
import "./realstate.css"; // Make sure to create this CSS file
import img1 from "./assets/WhatsApp Image 2024-08-26 at 2.51.05 PM.jpeg";


const RealEstate = () => {
  const [selected, setSelected] = useState("Buy");
 

  
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            Real Estate Support Services | Buy, Sell, and Manage Properties
          </title>
          <meta
            name="description"
            content="Get professional support for buying, selling, or managing real estate. Our experienced team offers comprehensive real estate services. Contact us for assistance."
          />
          <meta name="robots" content="index, follow" />
          <meta
            property="og:title"
            content="Real Estate Support Services | Buy, Sell, and Manage Properties"
          />
          <meta
            property="og:description"
            content="Get professional support for buying, selling, or managing real estate. Our experienced team offers comprehensive real estate services. Contact us for assistance."
          />
          <meta property="og:image" content={img1} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Real Estate Support Services | Buy, Sell, and Manage Properties"
          />
          <meta
            name="twitter:description"
            content="Get professional support for buying, selling, or managing real estate. Our experienced team offers comprehensive real estate services. Contact us for assistance."
          />
          <meta name="twitter:image" content={img1} />
        </Helmet>
        <div className="realstate_text">
          <div className="realstate1">
            {/* Real Estate Navbar */}

            <header className="RealPortal_nav">
              <nav
                className={`RealPortal_navlink ${
                  selected === "Buy" ? "active" : ""
                }`}
                onClick={() => setSelected("Buy")}
              >
                <img
                  className="jobportal_icon"
                  src={BuyIcon}
                  alt="Buy Property Icon"
                />
                Buy
              </nav>
              <nav
                className={`RealPortal_navlink ${
                  selected === "Rent" ? "active" : ""
                }`}
                onClick={() => setSelected("Rent")}
              >
                <img
                  className="jobportal_icon"
                  src={RentIcon}
                  alt="Rent Property Icon"
                />
                Rent
              </nav>
              <nav
                className={`RealPortal_navlink ${
                  selected === "Sell" ? "active" : ""
                }`}
                onClick={() => setSelected("Sell")}
              >
                <img
                  className="jobportal_icon"
                  src={SellIcon}
                  alt="Sell Property Icon"
                />
                Sell
              </nav>
              <nav
                className={`RealPortal_navlink ${
                  selected === "HomeLoans" ? "active" : ""
                }`}
                onClick={() => setSelected("HomeLoans")}
              >
                <img
                  className="jobportal_icon"
                  src={HomeloanIcon}
                  alt="Home Loans Icon"
                />
                Home Loans
              </nav>
              <nav
                className={`RealPortal_navlink ${
                  selected === "PG" ? "active" : ""
                }`}
                onClick={() => setSelected("PG")}
              >
                <img className="jobportal_icon" src={PGIcon} alt="PG Icon" />
                PG
              </nav>
              <nav
                className={`RealPortal_navlink ${
                  selected === "Help" ? "active" : ""
                }`}
                onClick={() => setSelected("Help")}
              >
                <img
                  className="jobportal_icon"
                  src={HelpIcon}
                  alt="Help Icon"
                />
                Help
              </nav>
            </header>

            <div >
              {selected === 'Buy' && <Main/>}
               {selected === 'Sell' && <SellPage/> }
            </div>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
};

export default RealEstate;
