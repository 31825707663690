import React, { useState, useRef } from 'react';
import './EmployersSignin.css'; // Make sure to create and import the corresponding CSS file
import Modal from 'react-modal';
import EmployersRegister from "./EmployersRegister";
import JobPortalHome from "./Home"

const customStyles = {
  content: {
    top: '55%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '95%', // Set a responsive width
    maxWidth: '600px', // Limit the maximum width on larger screens
    height: '550px',
    padding:'10px',
    paddingTop: '5px',
  },
};

const EmployersSignin = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignin = (e) => {
    e.preventDefault();
    // Handle the signin logic here
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const subtitleRef = useRef(null); // Create a ref for the subtitle

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    if (subtitleRef.current) {
      subtitleRef.current.style.color = '#f00';
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="signin-container">
      <h2>Employers Sign in</h2>
      <p>Enter your mobile number & Email id for Login</p>
      
      <form onSubmit={handleSignin}>
        <div className="input-group">
          <label htmlFor="mobileNumber">Mobile Number</label>
          <input 
            type="text" 
            id="mobileNumber" 
            placeholder="Enter your Mobile number here"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            required 
          />
        </div>

        <div className="input-group">
          <label htmlFor="email">Email id</label>
          <input 
            type="email" 
            id="email" 
            placeholder="Enter your email id here"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required 
          />
        </div>

        <div className="input-group password-group">
          <label htmlFor="password">Password</label>
          <input 
            type="password" 
            id="password" 
            placeholder="Minimum 6 characters"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required 
          />
          <a href="/forgot-password" className="forgot-password">Forgot Password</a>
        </div>

        <button type="submit" className="signin-btn">Continue</button>
      </form>
      
      <p className="signup-link">
        <span>Don’t have an account?</span>{" "}
         <button className='signupbtn' onClick={openModal}>sign up</button>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button onClick={closeModal} className='close-buttonEsignup'>&times;</button>
          <EmployersRegister/>
        </Modal>
      </p>
    </div>
  );
};

export default EmployersSignin;
