import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Banner7 from '../assets/Frame 1431.png';
import Banner8 from './assets/Mask group.png';
import Dp from './assets/Group 118.png';
import Ur from './assets/Group 118 (1).png';
import Ui from './assets/Group 118 (2).png';
import Ux from './assets/Mask group (1).png';
import Uid from './assets/Group 118 (3).png';
import D from './assets/Mask group (2).png';
import Uig from './assets/Group 118 (4).png';
import I from './assets/Group 118 (5).png';
import P from './assets/Group 118 (6).png';
import T from './assets/Group 118 (7).png';

const UiUx = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>UI/UX Designing Services | Enhance User Experience</title>
          <meta
            name="description"
            content="Optimize your digital products with our UI/UX designing services. Improve user experience and engagement with intuitive designs. Contact us today."
          />
        </Helmet>
        <div className="uiux-text">
          <header className="uiux1">
            <section className="banners">
              <Link className="contact_btn" to="/contact/">
                <button className="btn_contact2">Contact Us</button>
              </Link>
              <img src={Banner7} className="uxui_banner1" alt="UI/UX Design Banner" />
              <div className="digital_banner_content">
                <div className="digital_banner_content_img">
                  <img src={Banner8} alt="Why UI/UX Design" className="digital_banner_img2" />
                </div>
                <div className="digital_banner_content_text">
                  <h2 className="digital_banner_header1">WHY UI/UX Design?</h2>
                  <h3 className="digital_banner_header2">WHY CHOOSE RUBIA FOR UI/UX DESIGN SERVICES?</h3>
                  <p className="digital_banner_simpleText">
                    Transform your digital presence with Rubia's top-notch UI/UX design services. Our skilled designers create intuitive and engaging interfaces that provide seamless user experiences, making your brand stand out. From mobile apps to web platforms, Rubia ensures every touchpoint is visually appealing and user-friendly. Choose Rubia to elevate your digital products and delight your users with exceptional design.
                  </p>
                </div>
              </div>
            </section>
            <section className="software_banner_main_content">
              <h2 className="software_banner_main_content_header">UI/UX-DESIGN</h2>
              <h3 className="software_banner_main_content_header1">Designing Easy and Enjoyable User Experiences</h3>
            </section>

            <section className="software_details_container">
              {[
                { img: Dp, header: 'Designing Process', text: 'Designers of all levels can benefit from a solid understanding of the UX design process. By following a well-defined process, you can take an idea and turn it into a user-friendly interface that meets the needs of your customers.' },
                { img: Ur, header: 'User Research', text: 'In this phase, designers conduct research to gain a deep understanding of their users and their needs. This research helps them create empathy for their users and understand what they need from the product or service.' },
                { img: Ui, header: 'UI Design', text: 'Once you have a good understanding of your users and a plan to move forward, it\'s time to start sketching out some ideas for how they’ll interact with your interface. This is where you\'ll start to think about things like the overall layout, navigation, and specific elements.' },
                { img: Ux, header: 'UX Design', text: 'It helps designers create interfaces that meet the needs of their users. By following a well-defined process, designers can be sure that they are covering all of the necessary bases and creating an interface that will be user-friendly and effective.' },
                { img: Uid, header: 'UI Development', text: 'A UI (User Interface) developer specializes in designing and developing the visual and interactive components of websites, web applications, and mobile applications. The user interface has a direct impact on user experience.' },
                { img: D, header: 'Define', text: 'In the first phase, it\'s necessary to determine exactly what needs to be created, and why. Why does this product need to exist? Who are you creating this for? What business problems will this solve?' },
                { img: Uig, header: 'UI Guidelines', text: 'An SPA (Single-page application) is a web app implementation that loads only a single web document, and then updates the body content of that single document via JavaScript APIs such as Fetch when different content is to be shown.' },
                { img: I, header: 'Ideation', text: 'In the planning phase, designers take all of the information they gathered in the research stage and start to plan out how they’ll meet those needs. They develop user personas, user stories, wireframes, and other high-level plans during this phase.' },
                { img: P, header: 'Prototype', text: 'Once you have a working UI, it\'s time to turn it into a working prototype. Prototyping allows you to present a more realistic experience for your usability testing, which can then deliver more accurate feedback and insight into "what\'s working" and "what\'s not working".' },
                { img: T, header: 'Testing', text: 'Before launch, it\'s important to test the interface with real users. Usability testing helps identify any areas that need improvement before the final product goes live, and delivers this feedback from the user\'s point of view.' },
              ].map((item, index) => (
                <article className="software_details_content" key={index}>
                  <img src={item.img} alt={item.header} className="software_details_img2" />
                  <div>
                    <h4 className="software_details_header">{item.header}</h4>
                    <p className="software_details_simpletext">{item.text}</p>
                  </div>
                </article>
              ))}
            </section>

            <div className="contact-section w-100 d-flex flex-row justify-content-between align-items-center mt-5">
              <Link
                to="/contact/"
                className="rounded-2 fw-bold"
                style={{
                  height: '49px',
                  border: 'none',
                  background: '#C68643',
                  color: '#FFF',
                  padding: '10px 10px',
                  fontSize: '20px',
                  margin: 'auto',
                  textDecoration: 'none',
                }}
              >
                Contact us for more details
              </Link>
            </div>
          </header>
        </div>
      </HelmetProvider>
    </>
  );
};

export default UiUx;
