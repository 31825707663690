import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import Nav from '../nav/nav';
import Leftside from "../leftside/leftside";
import Home from "../Home/home";
import Service from "../middlecontent/middlecontent";
import About from "../About/about";
import Contact from "../Contact/Contact";
import BussinessListing from '../BussinessListing/BussinessListing';
import Driver from "../DriverServices/DriverServices";
import Graphic from '../graphicdesiging/graphic';
import Software from '../software/software';
import UiUx from '../uiux/UiUx';
import Taxi from "../taxi/taxi";
import DigitalMarketing from "../Digital_marketing/DigitalMarketing";
import Realstate from '../realstate/realstate';
import JobPortal from '../Job_portal/jobPortal';
import Bregistration from '../BussinessListing/Bregistration/Bregistration';
import DriverRegister from '../DriverServices/DriverRegister';
import PrivateServices from '../BussinessListing/PrivateServices/PrivateServices';
import GovermentServices from '../BussinessListing/GovermentServices/GovermentServices';
import Applink from "../AppLinks/applink";
import Footer from "../footer/Footer";
import Profile from '../Authentication/Login';
import Login from '../Authentication/Login';
import Register from '../Authentication/Register';
import Rightside from "../Rightside/rightside";
import Privacypolicy from "../PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "../RefundPolicy/RefundPolicy";
import TermsConditions from "../Terms&conditions/TermsConditions";
import Electricity from "../Electricity/Electricity";
import Contact1 from '../Contact/contact1';
// import JobPortalHome from "../Job_portal/Home";

const Routing = () => {
  return (
    <>
      <Leftside />
      <Rightside />
      <Nav />
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/service/' element={<Service />} />
        <Route path='/about/' element={<About />} />
        <Route path='/contact/' element={<Contact />} />
        <Route path='/businesslisting/' element={<BussinessListing />} />
        <Route path='/driver/' element={<Driver />} />
        <Route path='/taxi/' element={<Taxi />} />
        <Route path='/digital marketing/' element={<DigitalMarketing />} />
        <Route path='/graphic desiging/' element={<Graphic />} />
        <Route path='/software/' element={<Software />} />
        <Route path='/ui/ux desigining/' element={<UiUx />} />
        <Route path='/job portal/' element={<JobPortal />} />
        <Route path='/real state/' element={<Realstate />} />
        <Route path='/Bregistration/' element={<Bregistration />} />
        <Route path='/driverregister/' element={<DriverRegister />} />
        <Route path='/privateservices/' element={<PrivateServices />} />
        <Route path='/govermentservices/' element={<GovermentServices />} />
        <Route path='/profile/' element={<Profile />} />
        <Route path='/Login' element={<Login />} />
        <Route path='/Register/' element={<Register />} />
        <Route path='/Privacypolicy/' element={<Privacypolicy />} />
        <Route path='/RefundPolicy/' element={<RefundPolicy />} />
        <Route path='/TermsConditions/' element={<TermsConditions />} />
        <Route path='/Electricity/' element={<Electricity />} />
        <Route path='/job portal/' element={<JobPortal/>} />
        <Route path='/contact1/'  element={<Contact1/>}/>
        {/* <Route path='/jobhome/' element={<JobPortalHome/>}/> */}
      </Routes>
      <Applink />
     <Footer/>
    </>
  );
};

const App = () => (
  <BrowserRouter>
    <Routing />
  </BrowserRouter>
);

export default App;
