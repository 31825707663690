import React from "react";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Banner4 from "../assets/Frame 1432.png";
import Banner5 from "./assets/BannerSofteware.png";
// import Banner6 from "../assets/Frame 248.png";
import Html from "./assets/Group 118 (1).png";
import Asp from "./assets/Mask group.png";
import Spw from "./assets/Mask group (1).png";
import Mpw from "./assets/Mask group (2).png";

const Software = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Software Development Services | Custom Solutions for Your Business
        </title>
        <meta
          name="description"
          content="Develop custom software solutions tailored to your business needs. Our expert developers offer scalable and secure software services. Get a free consultation."
        />
      </Helmet>

      <main className="software-text">
        <section className="software1">
          <header className="banners">
            <Link className="contact_btn" to="/contact/" aria-label="Contact Us">
              <button className="btn_contact_sw">Contact us</button>
            </Link>
            <img src={Banner4} className="software_banners1" alt="Software Development Banner" />
          </header>

          <section className="software_banner_content">
            <div className="software_banner_content_img">
              <img src={Banner5} alt="Banner Software" className="software_banner_img2" />
            </div>
            <article className="software_banner_content_text">
              <h2 className="software_banner_header1">
                WHY WEBSITE DESIGN & DEVELOPMENT?
              </h2>
              <h3 className="software_banner_header2">
                WHY CHOOSE RUBIA FOR WEBSITE DESIGN & DEVELOPMENT SERVICES?
              </h3>
              <p className="software_banner_simpleText">
                Why choose Rubia for website design and development services?
                Rubia offers a blend of creativity and technical expertise to
                create visually appealing, user-friendly websites that elevate
                your online presence. Our experienced team tailors each
                project to your specific needs, ensuring a seamless and
                engaging user experience. Committed to quality and timely
                delivery, Rubia transforms your vision into a dynamic,
                responsive website that drives results.
              </p>
            </article>
          </section>

          <section className="software_banner_main_content">
            <h2 className="software_banner_main_content_header">WEB-DESIGN</h2>
            <h3 className="software_banner_main_content_header1">
              WEB DESIGN & DEVELOPMENT: BUILDING THE FOUNDATION
            </h3>
          </section>

          <section className="software_details_container">
            <article className="software_details_content" aria-labelledby="static-website">
              <div className="software_details_img">
                <img src={Html} alt="HTML" className="software_details_img2" />
              </div>
              <div>
                <h4 id="static-website" className="software_details_header">Static Website</h4>
                <p className="software_details_simpletext">
                  Made of “fixed code,” and unless the site developer makes
                  changes, nothing will change on the page. Think of it like a
                  brochure for a business. Static sites give a lot of the same
                  type of information that you could get from a brochure, but
                  it can't just change itself.
                </p>
              </div>
            </article>

            <article className="software_details_content" aria-labelledby="dynamic-website">
              <div className="software_details_img">
                <img src={Asp} alt="ASP" className="software_details_img2" />
              </div>
              <div>
                <h4 id="dynamic-website" className="software_details_header">Dynamic Website</h4>
                <p className="software_details_simpletext">
                  A web page constructed at runtime (during software
                  execution), as opposed to a static web page, delivered as it
                  is stored. A server-side dynamic web page is a web page
                  whose construction is controlled by an application server
                  processing server-side scripts.
                </p>
              </div>
            </article>

            <article className="software_details_content" aria-labelledby="single-page-website">
              <div className="software_details_img">
                <img src={Spw} alt="SPW" className="software_details_img2" />
              </div>
              <div>
                <h4 id="single-page-website" className="software_details_header">Single Page Website</h4>
                <p className="software_details_simpletext">
                  An SPA (Single-page application) is a web app implementation
                  that loads only a single web document and then updates the
                  body content of that single document via JavaScript APIs
                  such as Fetch when different content is to be shown.
                </p>
              </div>
            </article>

            <article className="software_details_content" aria-labelledby="multi-page-website">
              <div className="software_details_img">
                <img src={Mpw} alt="MPW" className="software_details_img2" />
              </div>
              <div>
                <h4 id="multi-page-website" className="software_details_header">Multi Page Website</h4>
                <p className="software_details_simpletext">
                  Multiple-page applications work in a “traditional” way.
                  Every change, e.g., displaying the data or submitting data
                  back to the server, requests rendering a new page from the
                  server in the browser. These applications are large, bigger
                  than SPAs because they need to be.
                </p>
              </div>
            </article>
          </section>

          <footer className="w-100 d-flex flex-row justify-content-between align-items-center mt-1">
          <div className="w-100 d-flex flex-row justify-content-between align-items-center mt-5">
          <Link
            to="/contact/"
            className="rounded-2 fw-bold"
            style={{
              height: "49px",
              border: "none",
              background: "#C68643",
              color: "#FFF",
              padding: "10px 10px",
              fontSize: "20px",
              margin: "auto",
              textDecoration: "none",
            }}
          >
            Contact us for more details
          </Link>
        </div>
          </footer>
        </section>
      </main>
    </HelmetProvider>
  );
};

export default Software;
