import React from "react";
import { Link } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";

// Importing assets
import Banner1 from "../assets/Card143.png";
import Banner2 from "./assets/Mask group.png";
import BusinessCard from "./assets/image 94.png";
import Flyer from "./assets/image 96.png";
import Logo from "./assets/image 97.png";
import Advertisement from "./assets/bxs_offer.png";
import Collage from "./assets/iconoir_collage-frame.png";
import Presentation from "./assets/image 98.png";
import ProfilePicture from "./assets/image 101.png";
import Poster from "./assets/poster.png";
import Brochure from "./assets/Brocher.png";
import Invitation from "./assets/invitation.png";
import Menu from "./assets/Menu.png";
import Card from "./assets/card.png";
import Certificate from "./assets/certifation.png";
import Resume from "./assets/image 92.png";
import Invoice from "./assets/invoice.png";
import Thumbnail from "./assets/youtube.png";
import Banner from "./assets/youtube.png";
import Meme from "./assets/Meme.png";

const Graphic = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Graphic Designing Services | Creative Designs for Your Brand</title>
          <meta
            name="description"
            content="Transform your brand with our creative graphic designing services. Logos, brochures, banners, and more. Contact us to start your design project."
          />
        </Helmet>

        <main className="graphic-text">
          <section className="graphic1">
            <header className="banners">
              <Link className="contact_btn" to="/contact/" aria-label="Contact Us">
                <button className="btn_contactg">Contact Us</button>
              </Link>
              <img
                className="Banner1_graphic"
                src={Banner1}
                alt="Graphic Design Banner"
              />
            </header>

            <section className="graphic_banner_content">
              <div className="graphic_banner_content_img">
                <img
                  src={Banner2}
                  alt="Creative Design"
                  className="graphic_banner_img2"
                />
              </div>
              <article className="graphic_banner_content_text">
                <h2 className="graphic_banner_header1">WHY GRAPHIC DESIGNING?</h2>
                <h3 className="graphic_banner_header2">
                  WHY CHOOSE RUBIA FOR GRAPHIC DESIGN SERVICES?
                </h3>
                <p className="graphic_banner_simpleText">
                  At Rubia, we combine creativity and precision to deliver stunning,
                  custom visual solutions that elevate your brand. Our experienced team
                  ensures every project meets your unique needs and goals. With a
                  commitment to quality, attention to detail, and timely delivery, Rubia
                  transforms your vision into captivating designs. Choose Rubia for
                  professional, personalized graphic design services that make your brand
                  shine.
                </p>
              </article>
            </section>

            <section className="categories-container">
              <h2 className="categories-header">CATEGORIES</h2>

              <div className="categories-main">
                <div className="categories-Marketing" role="region" aria-labelledby="marketing">
                  <h3 id="marketing" className="categories-headings">Marketing</h3>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={BusinessCard} alt="Business Card" />
                    </div>
                    <p className="categories-text">Business card</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Flyer} alt="Flyer" />
                    </div>
                    <p className="categories-text">Flyer</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Logo} alt="Logo" />
                    </div>
                    <p className="categories-text">Logo</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Advertisement} alt="Advertisement" />
                    </div>
                    <p className="categories-text">Advertisement</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Collage} alt="Collage" />
                    </div>
                    <p className="categories-text">Collage</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Presentation} alt="Presentation" />
                    </div>
                    <p className="categories-text">Presentation</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={ProfilePicture} alt="Profile Picture" />
                    </div>
                    <p className="categories-text">Profile Picture</p>
                  </div>
                </div>

                <div className="categories-Document" role="region" aria-labelledby="document">
                  <h3 id="document" className="categories-headings">Document</h3>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Poster} alt="Poster" />
                    </div>
                    <p className="categories-text">Poster</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Brochure} alt="Brochure" />
                    </div>
                    <p className="categories-text">Brochure</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Invitation} alt="Invitation" />
                    </div>
                    <p className="categories-text">Invitation</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Menu} alt="Menu" />
                    </div>
                    <p className="categories-text">Menu</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Card} alt="Card" />
                    </div>
                    <p className="categories-text">Card</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Certificate} alt="Certificate" />
                    </div>
                    <p className="categories-text">Certificate</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Resume} alt="Resume" />
                    </div>
                    <p className="categories-text">Resume</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Invoice} alt="Invoice" />
                    </div>
                    <p className="categories-text">Invoice</p>
                  </div>
                </div>

                <div className="Social-Media" role="region" aria-labelledby="social-media">
                  <h3 id="social-media" className="categories-headings">Social Media</h3>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Thumbnail} alt="Thumbnail" />
                    </div>
                    <p className="categories-text">Thumbnail</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Banner} alt="Banner" />
                    </div>
                    <p className="categories-text">Banner</p>
                  </div>

                  <div className="categories-content">
                    <div className="categories-icons">
                      <img src={Meme} alt="Meme" />
                    </div>
                    <p className="categories-text">Meme</p>
                  </div>
                </div>
              </div>
            </section>

            <footer className="w-100 d-flex flex-row justify-content-between align-items-center mt-1">
          <div className="w-100 d-flex flex-row justify-content-between align-items-center mt-5">
          <Link
            to="/contact/"
            className="rounded-2 fw-bold"
            style={{
              height: "49px",
              border: "none",
              background: "#C68643",
              color: "#FFF",
              padding: "10px 10px",
              fontSize: "20px",
              margin: "auto",
              textDecoration: "none",
            }}
          >
            Contact us for more details
          </Link>
        </div>
          </footer>
          </section>
        </main>
      </HelmetProvider>
    </>
  );
};

export default Graphic;
