import React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import "./Jobs.css";
import CompanyIogo from "../assets/image 1.svg";
import Salary from "../assets/Group 109.svg";
import Experience from "../assets/ph_bag-simple-light.svg"
import Skill from "../assets/teenyicons_bulb-on-outline.svg"
import Education from "../assets/carbon_education.svg"
import Save from "../assets/Frame 210.svg"

const Jobs = () => {
  // Define the options arrays
  const jobpost = [
    {
      postname:"UI/UX Designer",
      companylogo:CompanyIogo,
      name:"Rubia.services",
      location:"Hyderabad, Erragadda ",
      salary:"₹15,000 - 30,000",
      skill:"Figma , Photoshop , Adobe XD , Protopie , Maze ",
      experience:"3 Months Experience",
      education:"Bachelor’s degree ",
    },
    {
      postname:"UI/UX Designer",
      companylogo:CompanyIogo,
      name:"Rubia.services",
      location:"Hyderabad, Erragadda ",
      salary:"₹15,000 - 30,000",
      skill:"Figma , Photoshop , Adobe XD , Protopie , Maze ",
      experience:"3 Months Experience",
      education:"Bachelor’s degree ",
    },
    {
      postname:"UI/UX Designer",
      companylogo:CompanyIogo,
      name:"Rubia.services",
      location:"Hyderabad, Erragadda ",
      salary:"₹15,000 - 30,000",
      skill:"Figma , Photoshop , Adobe XD , Protopie , Maze ",
      experience:"3 Months Experience",
      education:"Bachelor’s degree ",
    },
    {
      postname:"UI/UX Designer",
      companylogo:CompanyIogo,
      name:"Rubia.services",
      location:"Hyderabad, Erragadda ",
      salary:"₹15,000 - 30,000",
      skill:"Figma , Photoshop , Adobe XD , Protopie , Maze ",
      experience:"3 Months Experience",
      education:"Bachelor’s degree ",
    },
    {
      postname:"UI/UX Designer",
      companylogo:CompanyIogo,
      name:"Rubia.services",
      location:"Hyderabad, Erragadda ",
      salary:"₹15,000 - 30,000",
      skill:"Figma , Photoshop , Adobe XD , Protopie , Maze ",
      experience:"3 Months Experience",
      education:"Bachelor’s degree ",
    },

  ]
  const options0 = [
    { value: 'Date Posted', label: 'Date Posted' },
    { value: 'Last 03 days', label: 'Last 03 days' },
    { value: 'Last 07 days', label: 'Last 07 days' },
    { value: 'Since you last visit', label: 'Since you last visit' }
  ];

  const options1 = [
    { value: 'Remote', label: 'Remote' },
    { value: 'On site', label: 'On site' },
    { value: 'Hybrid work', label: 'Hybrid work' }
  ];

  const options2 = [
    {value:'Education', label:'Education Level'}, 
  {value:'Bachelors degree',label:'Bachelor"s degree'},
  {value:'Masters degree',label:'Masters degree'},
  ];

  const options3 = [
    { value: 'With in 25 Kilometers', label: 'With in 25 Kilometers' },
    { value: 'With in 40 Kilometers', label: 'With in 40 Kilometers' },
    { value: 'With in 50 Kilometers', label: 'With in 50 Kilometers' },
    { value: 'With in 100 Kilometers', label: 'With in 100 Kilometers' },
  ];

  const options4 = [
    { value: 'Job Type', label: 'Job Type' },
    { value: 'Full time', label: 'Full time' },
    { value: 'Contract', label: 'Contract' },
    { value: 'Internship', label: 'Internship' },
    { value: 'Part -time', label: 'Part -time' }
  ];

  // Create an array of all options arrays
  const allOptions = [options0, options1, options2, options3, options4];

  // Create an array of default options for each dropdown
  const defaultOptions = [
    options0[0],
    options1[0],
    options2[0],
    options3[0],
    options4[0]
  ];

  return (
    <div>
      <div className='filter'>
        {allOptions.map((options, index) => (
          <Dropdown
            key={index} // Assign a unique key to each dropdown
            options={options}
            value={defaultOptions[index]} // Use the corresponding default option
            placeholder="Select an option"
          />
        ))}
      </div>
      {jobpost.map((j)=>(
      <div className='jobsposter-container'>
        
        <div className='TitleOfPost'>
          <div className='TitleContainer'>{j.postname}</div>
          <div className='Duration'>Posted : 1 hr ago</div>
        </div>
        <div className='verticalline'></div>
        <div className='JobpostMiddle-container'>
          <div className='Companydetails'>
            <div className='CompanyIcon'><img src={j.companylogo} alt="" /></div>
            <div className='CompanyNameLocation'>
              <span className='CompanyName'>{j.name}</span>
              <span className='CompanyLocation'> {j.location} </span>
            </div>
          </div>
          <div className='DetailsOfJob'>
            <div className='DetailIcons'>
              <div ><img className='Icons_jobs' src={Salary} alt="" /></div>
              <div ><img className='Icons_jobs' src={Experience} alt="" /></div>
              <div ><img className='Icons_jobs' src={Skill} alt="" /></div>
              <div ><img className='Icons_jobs' src={Education} alt="" /></div>
            </div>
            <div className='Details'>
              <div className='salary'> {j.salary}</div>
              <div className='Experience'>{j.experience}</div>
              <div className='Skills'> {j.skill} </div>
              <div className='Education'>{j.education}</div>
            </div>
          </div>
        </div>
        <div className='verticalline'></div>
        <div className='ApplySave-container'>
          <div className='justempty'></div>
          <div className='ApplySave'>
            <img className='Save' src={Save} alt="" />
            <button className='Apply'>
              Apply
            </button>
            </div>
          
        </div>
        
      </div>
      ))}
    </div>
  );
}

export default Jobs;
