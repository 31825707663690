import React from "react";
import { Link } from "react-router-dom";
import Img2 from "./assets/Mask group.png";
import Img3 from "./assets/Frame 249.png";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Digitalmarketing = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Digital Marketing Services | Boost Your Online Presence</title>
        <meta
          name="description"
          content="Increase your online visibility and drive traffic with our digital marketing services. SEO, social media, PPC, and more. Let's grow your business together."
        />
      </Helmet>

      <main className="digital-text">
        <section className="digital1">
          <section id="digital2" >
          <header className="d-main mt-4 d-block">
            <Link className="contact_btn" to="/contact/" aria-label="Contact Us">
              <button className="btn_contact_dg">Contact Us</button>
            </Link>
            <div className="Dg_banner1">
              <img
                className="dg_banner2"
                src={Img3}
                alt="Digital Marketing Banner"
              />
            </div>
          </header>

          <section className="digital_banner_content">
            <div className="digital_banner_content_img">
              <img
                src={Img2}
                alt="Why Digital Marketing"
                className="digital_banner_img2"
              />
            </div>
            <article className="digital_banner_content_text">
              <h2 className="digital_banner_header1">WHY Digital Marketing?</h2>
              <h3 className="digital_banner_header2">
                WHY CHOOSE RUBIA FOR DIGITAL MARKETING SERVICES?
              </h3>
              <p className="digital_banner_simpleText">
                Rubia combines strategic insight and creative innovation to
                boost your online presence and drive measurable results. Our
                experienced team tailors campaigns to your specific goals,
                ensuring maximum engagement and ROI. Committed to quality and
                timely execution, Rubia helps you connect with your target
                audience and achieve sustained growth. Choose Rubia for
                effective, customized digital marketing solutions.
              </p>
            </article>
          </section>

          <section className="card dg-card">
            <article className="card-body">
              <h2 className="card-title dg-card_title1">DIGITAL MARKETING</h2>
              <h3
                style={{ color: "#650000", fontWeight: "700" }}
                className="card-title dg-card_title2"
              >
                GETTING MORE EYES ON YOUR BRAND
              </h3>

              <div className="d-flex flex-row justify-content-between ">
                <div
                  style={{ color: "#995050" }}
                  className="dg-block justify-content-start mt-5 text-start"
                >
                  <p>
                    <b>Email Marketing</b>
                  </p>
                  <p>
                    <b>ASO</b>
                  </p>
                  <p>
                    <b>Content Marketing</b>
                  </p>
                </div>

                <div
                  style={{ color: "#995050" }}
                  className="dg-block justify-content-center mt-5 text-start"
                >
                  <p>
                    <b>Branding</b>
                  </p>
                  <p>
                    <b>PPC Campaign</b>
                  </p>
                  <p>
                    <b>Video Marketing</b>
                  </p>
                </div>

                <div
                  style={{ color: "#995050" }}
                  className="dg-block justify-content-center mt-5 text-start"
                >
                  <p>
                    <b>SEO</b>
                  </p>
                  <p>
                    <b>SMO</b>
                  </p>
                  <p>
                    <b>SMM</b>
                  </p>
                </div>
              </div>
            </article>
          </section>

          <footer className="w-100 d-flex flex-row justify-content-between align-items-center mt-1">
          <div className="w-100 d-flex flex-row justify-content-between align-items-center mt-5">
          <Link
            to="/contact/"
            className="rounded-2 fw-bold"
            style={{
              height: "49px",
              border: "none",
              background: "#C68643",
              color: "#FFF",
              padding: "10px 10px",
              fontSize: "20px",
              margin: "auto",
              textDecoration: "none",
            }}
          >
            Contact us for more details
          </Link>
        </div>
          </footer>
          </section>
        </section>
      </main>
    </HelmetProvider>
  );
};

export default Digitalmarketing;
