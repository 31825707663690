import React, { useState } from "react";
import "./sell.css";

const Sell = () => {
  const [formValues, setFormValues] = useState({
    for: "",
    propertyType: "",
    areaName: "",
    district: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    bedrooms: "",
    balconies: "",
    bathrooms: "",
    furnishedStatus: "",
    possessionStatus: "",
    amenities: "",
    coveredArea: "",
    plotArea: "",
    expectedPrice: "",
    photos: [],
  });

  const [isOpen, setIsOpen] = useState({
    bedrooms: false,
    balconies: false,
    bathrooms: false,
  });

  const handleSelection = (field, value) => {
    setFormValues({ ...formValues, [field]: value });
    setIsOpen({ ...isOpen, [field]: false });
  };

  const toggleDropdown = (field) => {
    setIsOpen({ ...isOpen, [field]: !isOpen[field] });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormValues({ ...formValues, photos: [...e.target.files] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form submitted successfully:", formValues);
    } else {
      console.log("Form validation failed.");
    }
  };

  const validateForm = () => {
    let isValid = true;
    for (const key in formValues) {
      if (formValues[key] === "" && key !== "photos") {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  return (
    <form onSubmit={handleSubmit} className="property-form">
      <div className="section">
        <h1>Are You</h1>
        <div className="form-group">
          <div>
            <input
              type="radio"
              name="for"
              value="Owner"
              onChange={handleChange}
            />{" "}
            <span>Owner</span>
            <input
              type="radio"
              name="for"
              value="Agent"
              onChange={handleChange}
            />{" "}
            <span>Agent</span>
            <input
              type="radio"
              name="for"
              value="Builder"
              onChange={handleChange}
            />{" "}
            <span>Builder</span>
          </div>
        </div>
      </div>

      <div className="section">
        <h1>Property Details</h1>
        <div className="property-details-container">
          <div className="form-group">
            <label>For:</label>
            <div className="propertydeatails-radio">
              <input
                type="radio"
                name="for"
                value="Sale"
                onChange={handleChange}
              />{" "}
              <span>Sale</span>
              <input
                type="radio"
                name="for"
                value="Rent"
                onChange={handleChange}
              />{" "}
              <span>Rent</span>
              <input
                type="radio"
                name="for"
                value="PG/Hostel"
                onChange={handleChange}
              />{" "}
              <span>PG/Hostel</span>
            </div>
          </div>

          <div className="form-group">
            <label>Property Type</label>
            <select id="propertyType" name="propertyType" onChange={handleChange}>
              <option  value="">Select Property Type</option>
              <option value="Residential">Residential</option>
              <option value="Commercial">Commercial</option>
            </select>
          </div>
        </div>
      </div>

      <div className="section">
        <h1>Property Location</h1>
        <div className="propertyfield">
          <div className="form-group">
            <label>Area / Village Name</label>
            <input type="text" name="areaName" onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>City</label>
            <input type="text" name="city" onChange={handleChange} />
          </div>
        </div>

        <div className="propertyfield">
          <div className="form-group">
            <label>District</label>
            <input type="text" name="district" onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>State</label>
            <input type="text" name="state" onChange={handleChange} />
          </div>
        </div>

        <div className="propertyfield">
          <div className="form-group">
            <label>Country</label>
            <input type="text" name="country" onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Pin Code</label>
            <input type="text" name="pinCode" onChange={handleChange} />
          </div>
        </div>
      </div>

      <div className="section">
        <h1>Property Features</h1>
        <div className="propertyfield">
          <div className="form-group">
            <label>Bedrooms</label>
            <div className="dropdown-container">
            <div className="options">
              {[1, 2, 3, 4, 5, "6+"].map((num) => (
                <button
                  type="button"
                  key={num}
                  className={formValues.bedrooms === num ? "active" : ""}
                  onClick={() =>
                    setFormValues({ ...formValues, bedrooms: num })
                  }
                >
                  {num}
                </button>
              ))}
            </div>
            {formValues.bedrooms === "6+" && isOpen && (
              <div id="dropdown-menu1" className="dropdown-menu1  ">
                {[ 7, 8, 9].map((num) => (
                  <div
                    key={num}
                    className="dropdown-item"
                    id="dropdown-item"
                    onClick={() => handleSelection('bedrooms',num)}
                  >
                    {num}
                  </div>
                ))}
              </div>
            )}
            </div>
          </div>

          <div className="form-group">
            <label>Balconies</label>
            <div className="dropdown-container">
            <div className="options">
              {[1, 2, 3, 4, 5, "6+"].map((num) => (
                <button
                  type="button"
                  key={num}
                  className={formValues.balconies === num ? "active" : ""}
                  onClick={() => handleSelection('balconies',num)}
                >
                  {num}
                </button>
              ))}
            </div>
            {formValues.balconies === "6+" && isOpen && (
              <div id="dropdown-menu2" className="dropdown-menu1  ">
                {[ 7, 8, 9].map((num) => (
                  <div
                    key={num}
                    className="dropdown-item"
                    id="dropdown-item"
                    onClick={() => handleSelection('balconies',num)}
                  >
                    {num}
                  </div>
                ))}
              </div>
            )}
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>Bathrooms</label>
          <div className="dropdown-container">
            <div className="options">
              {[1, 2, 3, 4, 5, "6+"].map((num) => (
                <button
                  type="button"
                  key={num}
                  className={formValues.bathrooms === num ? "active" : ""}
                  onClick={() => handleSelection('bathrooms', num)}
                >
                  {num}
                </button>
              ))}
            </div>
            {formValues.bathrooms === "6+" && isOpen && (
              <div id="dropdown-menu3" className="dropdown-menu1  ">
                {[ 7, 8, 9].map((num) => (
                  <div
                    key={num}
                    className="dropdown-item"
                    id="dropdown-item"
                    onClick={() => handleSelection('bathrooms', num)}
                  >
                    {num}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="form-group">
          <label style={{ color: "#C58643" }}>Furnished Status</label>
          <div className="Status-home">
            <div>
              <input
                type="radio"
                name="furnishedStatus"
                value="Furnished"
                onChange={handleChange}
              />{" "}
              <span>Furnished</span>
            </div>
            <div>
              <input
                type="radio"
                name="furnishedStatus"
                value="Unfurnished"
                onChange={handleChange}
              />{" "}
              <span>Unfurnished</span>
            </div>
            <div>
              <input
                type="radio"
                name="furnishedStatus"
                value="Semi-Furnished"
                onChange={handleChange}
              />{" "}
              <span>Semi-Furnished</span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label style={{ color: "#C58643" }}>Possession Status</label>
          <div className="Status-home">
            <div>
              <input
                type="radio"
                name="possessionStatus"
                value="Ready to Move"
                onChange={handleChange}
              />{" "}
              <span>Ready to Move</span>
            </div>
            <div>
              <input
                type="radio"
                name="possessionStatus"
                value="Under Construction"
                onChange={handleChange}
              />{" "}
              <span>Under Construction</span>
            </div>
            <div>
              <input
                type="radio"
                name="possessionStatus"
                value="New Launch"
                onChange={handleChange}
              />{" "}
              <span>New Launch</span>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label style={{ color: "#C58643" }}>Amenities</label>
          <select id="propertyType" name="amenities" onChange={handleChange}>
            <option value="">Select Amenities</option>
            <option value="Gym">Gym</option>
            <option value="Swimming Pool">Swimming Pool</option>
          </select>
        </div>
      </div>

      <div className="section">
        <h1>Area</h1>
        <div className="propertyfield">
          <div className="form-group">
            <label>Covered Area</label>
            <input type="text" name="coveredArea" onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Plot Area</label>
            <input type="text" name="plotArea" onChange={handleChange} />
          </div>
        </div>
      </div>
      <div className="section">
        <h1>Price Details</h1>
        <div className="form-group">
          <label>Expected Price</label>
          <input type="text" name="expectedPrice" onChange={handleChange} />
        </div>
      </div>

      <div className="section">
        <h1>Photos</h1>
        <div className="form-group">
          <label>Upload Photos</label>
          <input
            type="file"
            name="photos"
            multiple
            onChange={handleFileChange}
          />
        </div>
      </div>

      <button type="submit" className="submit-button">
        Post
      </button>
    </form>
  );
};

export default Sell;
