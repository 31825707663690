const importAllImages1 = (r) => {
    return r.keys().map((assets) => {
      const name = assets.replace('./', '').replace(/\.[^/.]+$/, ""); // Extract name without extension
      return {
        name: name,
        image: r(assets)
      };
    });
  };
  
  const images1 = importAllImages1(require.context('./assets', false, /\.(png|jpe?g|svg)$/));
  
  export default images1;
  