import React, { useEffect, useRef, useState } from "react";
import "./Message.css";
import sandeep from "../assets/Rectangle 49.svg";
import Vishala from "../assets/Rectangle 49 (1).svg";
import Rohith from "../assets/Rectangle 49 (2).svg";
import ShivaKrishna from "../assets/Rectangle 49 (3).svg";
import Searchicon from "../assets/Vector.svg";
import Image from "../assets/ic_outline-photo.svg";
import Voice from "../assets/material-symbols_mic.svg";
import Sent from "../assets/iconamoon_send.svg"

const CustomFileInput = ({ image }) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <button onClick={handleClick} style={{ background: "none", border: "none", padding: 0 }}>
        <img className="image" src={image} alt="Upload" />
      </button>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e) => console.log(e.target.files[0])} // Handle the file input change
      />
    </>
  );
};

const Message = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);

  const users = [
    { name: "Sandeep", title: "UI/UX Designer", img: sandeep },
    { name: "Vishala", title: "UI/UX Designer", img: Vishala },
    { name: "Rohith", title: "UI/UX Designer", img: Rohith },
    { name: "Shiva Krishna", title: "Web Designer", img: ShivaKrishna },
  ];

  const selectUser = (user) => {
    setSelectedUser(user);
    setMessages([]); // Clear the messages when a new user is selected
  };

  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.ondataavailable = (event) => {
        setAudioChunks((prev) => [...prev, event.data]);
      };
    }
  }, [mediaRecorder]);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      recorder.start();
      setIsRecording(true);
    });
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);

      // Save the recording
      const blob = new Blob(audioChunks, { type: "audio/wav" });
      const audioURL = URL.createObjectURL(blob);
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: "voice", sender: "me", content: audioURL },
      ]);
      setAudioChunks([]);
    }
  };

  const handleSendMessage = () => {
    if (inputText.trim() !== "") {
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: "text", sender: "me", content: inputText.trim() },
      ]);
      setInputText("");
    }
  };

  const handleReceiveMessage = (content, type = "text") => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { type, sender: "them", content },
    ]);
  };

  const handleVoiceClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  return (
    <>
      <div className="search-main">
        <div className="text-message">Messages</div>
        <div className="search-container">
          <div className="search-icon">
            <img src={Searchicon} alt="Search Icon" />
          </div>
          <input type="text" className="search-input" placeholder="Search messages" />
        </div>
      </div>

      <div className="message-container">
        <div className="sidebar" style={{ borderRadius: "8px" }}>
          {users.map((user, index) => (
            <div
              key={index}
              className={`user-item ${selectedUser === user ? "active" : ""}`}
              onClick={() => selectUser(user)}
            >
              <img src={user.img} alt={user.name} className="user-avatar" />
              <div>
                <div className="user-name">{user.name}</div>
                <div className="user-title">{user.title}</div>
              </div>
              <hr />
            </div>
          ))}
        </div>

        <div className="chat-area" style={{ borderRadius: "8px" }}>
          {selectedUser ? (
            <>
              <div className="chat-header">
                <img src={selectedUser.img} alt={selectedUser.name} className="chat-avatar" />
                <div className="chat-user-info">
                  <div className="chat-user-name">{selectedUser.name}</div>
                  <div className="chat-user-title">{selectedUser.title}</div>
                </div>
                <div className="chat-actions">
                  <button>📞</button>
                  <button>➕</button>
                </div>
              </div>

              <div className="chat-messages">
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`message ${msg.sender === "me" ? "right" : "left"}`}
                  >
                    {msg.type === "text" ? (
                      <p>{msg.content}</p>
                    ) : (
                      <audio controls src={msg.content}></audio>
                    )}
                  </div>
                ))}
              </div>

              <div className="message-input">
                <input
                  type="text"
                  placeholder="Type a message"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                />
               
                <button onClick={handleSendMessage} >
                <img src={Sent} alt="" />
</button>
                <CustomFileInput image={Image} />
                <button onClick={handleVoiceClick}>
                  <img className="voice" src={Voice} alt="Voice Record" />
                </button>
                {isRecording && <p>Recording...</p>}
              </div>
            </>
          ) : (
            <div className="no-chat-selected">Select a user to start chatting</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Message;
