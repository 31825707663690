import React, { useState } from 'react';
import './EmployersRegister.css';

const EmployersRegister = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    accountType: '',
    companyName: '',
    designation: '',
    city: '',
    employees: '',
    gstNumber: '',
    createPassword: '',
    reenterPassword: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (formData.createPassword !== formData.reenterPassword) {
      newErrors.reenterPassword = 'Passwords do not match';
    }
    // Add more validation rules as needed
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log(formData);
      // Handle successful form submission
      alert('Form submitted successfully!');
      // Reset form
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: '',
        accountType: '',
        companyName: '',
        designation: '',
        city: '',
        employees: '',
        gstNumber: '',
        createPassword: '',
        reenterPassword: '',
      });
    }
  };
  
  return (
    <div className="employers-register-container">
      <h2 className="employers-register-title">Employers Sign Up</h2>
      <p className="employers-register-subtitle">Enter your mobile number & Email id to create an account</p>
      <form className="employers-register-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              placeholder="First name"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              placeholder="Last name"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="email">Email id</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email id here"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="mobileNumber">Mobile Number</label>
            <input
              type="tel"
              id="mobileNumber"
              placeholder="Enter your 10-digit mobile number"
              value={formData.mobileNumber}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="accountType">Account Type</label>
            <select
              id="accountType"
              value={formData.accountType}
              onChange={handleChange}
              required
            >
              <option value="">Select Account Type</option>
              <option value="employer">Employer</option>
              <option value="recruiter">Recruiter</option>
              {/* Add more options as necessary */}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              id="companyName"
              placeholder="Enter your company name"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="designation">Designation</label>
            <input
              type="text"
              id="designation"
              placeholder="Enter your designation"
              value={formData.designation}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              placeholder="Enter your city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="employees">No. of Employees</label>
            <select
              id="employees"
              value={formData.employees}
              onChange={handleChange}
              required
            >
              <option value="">Select range</option>
              <option value="1-10">1-10</option>
              <option value="11-50">11-50</option>
              <option value="51-200">51-200</option>
              <option value="201-500">201-500</option>
              <option value="500+">500+</option>
              {/* Add more options as necessary */}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="gstNumber">GST Number</label>
            <input
              type="text"
              id="gstNumber"
              placeholder="Enter your GST number"
              value={formData.gstNumber}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="createPassword">Create Password</label>
            <input
              type="password"
              id="createPassword"
              placeholder="Create Password"
              value={formData.createPassword}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="reenterPassword">Re-enter Password</label>
            <input
              type="password"
              id="reenterPassword"
              placeholder="Reenter Password"
              value={formData.reenterPassword}
              onChange={handleChange}
              required
            />
            {errors.reenterPassword && (
              <p className="error">{errors.reenterPassword}</p>
            )}
          </div>
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
      <p className="already-account">
        Already have an account? <a href="/signin">Sign in</a>
      </p>
    </div>
  );
};

export default EmployersRegister;
