import React, { useState } from 'react';
import Modal from 'react-modal';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import '../Job_portal/jobPortal.css';
import HomeIcon from "./assets/Home.svg";
import SearchIcon from "./assets/search.svg";
import JobsIcon from "./assets/job.svg";
import MyNetworksIcon from "./assets/my network.svg";
import MessageIcon from "./assets/message.svg";
import Search1Icon from "./assets/search1.svg";
import LocationIcon from "./assets/location.svg";
import Profileimg from "./assets/profile.png";
import JobPortalHome from "./pages/Home";  
import Jobs from './pages/Jobs';
import MyNetworks from './pages/MyNetworks'; 
import Message from './pages/Message';
// import Profile from './pages/Profile';
import Signin from './pages/JobSeekingSignin';
import EmployersSignin from "./pages/EmployersSignin"

const customStyles = {
  content: {
    top: '55%',
    left: '50%',
    right: 'auto',
    bottom: 'auto%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "600px",
    height: "550px",
    padding:'auto',
    paddingTop:'5px'
    
  },
};

const JobPortal = () => {
  const [selected, setSelected] = useState('JobPortalHome'); // Set initial state to 'JobPortalHome'
  const [modalIsOpen, setIsOpen] = useState(false); // Modal initially closed
  let subtitle;

  const openModal = () => {
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    if (subtitle) subtitle.style.color = '#C58643';
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleChange = (option) => {
    setSelected(option.value);
    openModal();
  };

  const options = [
    { value: 'JobseekerSignin', label: 'Job seeker Signin' },
    { value: 'EmployersSignin', label: 'Employers Signin' }
  ];

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Job Portal Services | Connect Employers and Job Seekers</title>
          <meta
            name="description"
            content="Find the best job opportunities or recruit top talent with our job portal services. Easy-to-use platform for both employers and job seekers. Explore now."
          />
        </Helmet>

        <div className="job_text">
          <div className="jobportal_nav">
            <div 
              className={`JobPortal_navlink ${selected === 'JobPortalHome' ? 'active' : ''}`}
              onClick={() => setSelected('JobPortalHome')}
            >
              <img className="jobportal_icon" src={HomeIcon} alt="Home Icon" />
              Home
            </div>
            <div 
              className={`JobPortal_navlink ${selected === 'Search' ? 'active' : ''}`}
              onClick={() => setSelected('Search')}
              id='jobportalsearch'
            >
              <img className="jobportal_icon" src={SearchIcon} alt="Search Icon" />
              Search
            </div>
            <div 
              className={`JobPortal_navlink ${selected === 'Jobs' ? 'active' : ''}`}
              onClick={() => setSelected('Jobs')}
            >
              <img className="jobportal_icon" src={JobsIcon} alt="Jobs Icon" />
              Jobs
            </div>
            <div 
              className={`JobPortal_navlink ${selected === 'MyNetworks' ? 'active' : ''}`}
              onClick={() => setSelected('MyNetworks')}
            >
              <img className="jobportal_icon" src={MyNetworksIcon} alt="My Networks Icon" />
              My Networks
            </div>
            <div 
              className={`JobPortal_navlink ${selected === 'Message' ? 'active' : ''}`}
              onClick={() => setSelected('Message')}
            >
              <img className="jobportal_icon" src={MessageIcon} alt="Message Icon" />
              Message
            </div>
            <div className="JobPortal_navlink" id="profileContainer">
              <img className="ImgProfile" src={Profileimg} alt="Profile" />
              <Dropdown 
                options={options} 
                onChange={handleChange} 
                value={"SIGN IN"} // Bind dropdown value to selected state
                placeholder="Select an option" 
              />
            </div>
          </div>

          {selected !== 'MyNetworks' && selected !== 'Message' && selected !== 'Signup' && (
            <div className="jobportal_searchcontainer">
              <div className="searchtext">Ready to Find Your Dream Job Now</div>
              <div className="searchinput_container">
                <img src={Search1Icon} alt="Search Icon" />
                <input type="text" placeholder="Jobs" className="jobportal_searchinput1" />
                <div className="vertical_line"></div>
                <img src={LocationIcon} alt="Location Icon" />
                <input type="text" className="jobportal_searchinput2" placeholder="Hyderabad" />
                <button className="searchbtn_jobportal">Search Jobs</button>
              </div>
            </div>
          )}

          <div className="job1">
            {selected === 'JobPortalHome' && <JobPortalHome />}
            {selected === 'Jobs' && <Jobs />}
            {selected === 'MyNetworks' && <MyNetworks />}
            {selected === 'Message' && <Message />}
            {selected === 'JobseekerSignin' && (
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Signin Modal"
              >
                <button onClick={closeModal} className='close-button'>&times;</button>
                <Signin /> {/* Render your Signin component here */}
              </Modal>
            )}
            {selected === 'EmployersSignin' && (
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Signup Modal"
              >
                <button onClick={closeModal} className='close-button'>&times;</button>
                <EmployersSignin /> {/* Render your Signup component here */}
              </Modal>
            )}
          </div>
        </div>
      </HelmetProvider>
    </>
  );
};

export default JobPortal;
